import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {TokenService} from './token.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

// const OAUTH_CLIENT = 'mobilet';
// const OAUTH_SECRET = 'pin';
// const API_URL = 'http://face.equaze.in:8080/mobilefaceapi/attendance/v1'
const API_URL=environment.baseUrl;

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization':  "Basic bW9iaWxlOnBpbg=="
  })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl = '';
  private static handleError(error: HttpErrorResponse): any {
    debugger
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Please enter correct username and password');
  }

  private static log(message: string): any {
    console.log(message);
  }
  token:any;
  private API_URL = environment.baseUrl;
  constructor(private http: HttpClient, private tokenService: TokenService) { 
    this.token= localStorage.getItem("access_token");
  
}
otpRequest:any;
login(loginData: any): Observable<any> {
 this.tokenService.removeToken();
 this.tokenService.removeRefreshToken();
 // const body = new HttpParams()
 //   .set('username', loginData.username)
 //   .set('password', loginData.password)
 //   .set('Scope', "READ WRITE")
 //   .set('grant_type', 'password');

 var body={
   "password": loginData.password,
   "username": loginData.username
 }

//var myHeaders = new Headers();
//HTTP_OPTIONS.headers.append("Authorization", "Basic bW9iaWxlOnBpbg==");
console.log(HTTP_OPTIONS.headers)

 return this.http.post<any>(API_URL + '/authenticate', body)
   .pipe(
     tap(res => {
       this.tokenService.saveToken(res.output.data.token);
       this.tokenService.saveRefreshToken(res.output.data.token);
     }),
     catchError(AuthService.handleError)
   );
}
refreshToken(refreshData: any): Observable<any> {
 debugger
 this.tokenService.removeToken();
 this.tokenService.removeRefreshToken();
 const body = new HttpParams()
   .set('refresh_token', refreshData.refresh_token)
   .set('grant_type', 'refresh_token');
 return this.http.post<any>(API_URL + 'oauth/token', body, HTTP_OPTIONS)
   .pipe(
     tap(res => {
       this.tokenService.saveToken(res.access_token);
       this.tokenService.saveRefreshToken(res.refresh_token);
     }),
     catchError(AuthService.handleError)
   );
}
logout(): void {
 debugger
 this.tokenService.removeToken();
 this.tokenService.removeRefreshToken();
}
register(data: any): Observable<any> {
  debugger
 return this.http.post<any>(API_URL + '/organizationRegister', data)
   .pipe(
     tap(_ => AuthService.log('register')),
     catchError(AuthService.handleError)
   );
}

secured(): Observable<any> {
 return this.http.get<any>(API_URL + 'employee/find')
   .pipe(catchError(AuthService.handleError));
}

public corporateRegister(json: any) {
  debugger
  this.otpRequest  = json.phoneNumber;
  return this.http.post(this.API_URL + "/corporateRegister",json);
}

public verifyOTP(json: any) {
  debugger
  return this.http.post(this.API_URL + "/verifyOtp",json);
}

public updateOrganization(json: any) {
  debugger
  return this.http.post(this.API_URL + "/updateCorporateRegister",json);
}

generateOtp(json)
{
  debugger
  return this.http.post(this.API_URL + "/otp/generateOtp",json);
  
}


sendInvite(json)
{
  debugger
  return this.http.post(this.API_URL + "/organization/sendInvite",json);
   
}
   
}
