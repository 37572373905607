import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
    if( document.getElementById('showCollapse') != null)
    {
    document.getElementById('showCollapse').style.display='none';
    }
    
  }

  onClick()
  {
    this.router.navigate(['/students'])
  }

  dashBoard()
  {
    this.router.navigate(['/dashboard']) 
  }
  onClickCandi()
  {
    this.router.navigate(['/candidates'])
  }
  attendanceClick()
  {
    this.router.navigate(['/attendance'])
    
  }

  onClickErp()
  {
    this.router.navigate(['/list-erp']) 
  }
  onClickGroup()
  {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate")
    this.router.navigate(['/list-group'])
  }
  attendanceClickEmployee()
  {
    this.router.navigate(['/attendance-employees'])
  }

  OrganizationUser(){
    this.router.navigate(['/organization-user'])
  }


  showClose(){
    if(document.getElementById('showCollapse')!=null){
    document.getElementById('showCollapse').style.display='block';
    }
  }

  onClickSite()
  {
    this.router.navigate(['/list-site'])
  }

  onClickBulkUpload(){
    this.router.navigate(['/bulk-upload'])
  }

  onClickMyUpload(){
    this.router.navigate(['/my-upload'])
  }


  onClickRoom()
  {
    this.router.navigate(['/list-room'])
  }

  onClickFloor()
  {
    this.router.navigate(['/list-floor'])
  }

  onClickBuilding()
  {
    this.router.navigate(['/list-building'])
  }

  onClickSynchro()
  {
    this.router.navigate(['/view-sync'])
  }
  onClickConfig()
  {
    this.router.navigate(['/list-config'])
  }

  onClickCode()
  {
    this.router.navigate(['/list-code-generation'])
  }

  onClickReports()
  {
    this.router.navigate(['/view-reports'])
  }
  onClickAssignLocation()
  {
    this.router.navigate(['/assign-location'])
  }

  onClickNotification()
  {
    this.router.navigate(['/notification'])
  }
}
